<template>
  <div class="case-criminal">
		<div class="search-box">
			<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
				<el-form-item label="案件名称">
					<el-input v-model="formSearch.companyName" placeholder="请输入案件名称"></el-input>
				</el-form-item>
				<el-form-item label="立案时间">
					<el-date-picker v-model="formSearch.timeLA" value-format="yyyy-MM-dd" type="daterange" align="right" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptionsLA"></el-date-picker>
				</el-form-item>
				<el-form-item label="报送地市" v-show="levelSession == 1">
					<el-select v-model="formSearch.cityId" clearable>
						<el-option v-for="(item,index) in formSearch.cityIdList" :key="index" :label="item.name" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="案件类型">
					<el-select v-model="formSearch.eventStatus" clearable>
						<el-option label="新发案件" :value="0"></el-option>
						<el-option label="存量案件" :value="1"></el-option>
						<el-option label="零上报" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="报送时间">
					<el-date-picker v-model="formSearch.timeBS" value-format="yyyy-MM-dd" type="daterange" align="right" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptionsBS"></el-date-picker>
				</el-form-item>
				<el-form-item style="text-align: right;" class="fr">
					<el-button type="primary" @click="searchNameFn">检索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- 列表 -->
		<div class="table-box case-tabs-box" style="position: relative;">
			<el-card>
				<el-tabs v-model="formSearch.uploadStatus" @tab-click="handleClick">
					<el-tab-pane label="台账" name="0"></el-tab-pane>
					<el-tab-pane label="历史上报报表" name="1"></el-tab-pane>
				</el-tabs>
				<h4 class="title-h4" style="float: left;">案件总数：<span>{{totalItems}}</span> 个</h4>
				<el-table border v-loading="loadingFlag" :data="tableData" @sort-change='sortChange' :default-sort="{prop: 'updateTime', order: 'descending'}" @selection-change="handleSelectionChange" :row-style="{height:'48px'}" empty-text="加载中..." style="width: 100%;">
					<el-table-column fixed type="selection" width="30"></el-table-column>
					<el-table-column fixed type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column fixed prop="provinceName" label="地区" width="150" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column fixed prop="caseName" label="案件名称" width="250" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="eventStatus" label="案件类型" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span v-if="scope.row.eventStatus == 0">新发案件</span>
							<span v-if="scope.row.eventStatus == 1">存量案件</span>
							<span v-if="scope.row.eventStatus == 2">零上报</span>
						</template>
					</el-table-column>
					<el-table-column prop="jbxxWdfje" label="涉案金额(万元)" width="120" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="jbxxJzrs" label="集资参与人数(人)" width="120" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="areaName" label="立案地" width="120" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="czqkLazm" label="立案罪名" width="150" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="czqkLasj" label="立案时间" width="90" :show-overflow-tooltip="true" sortable="custom"></el-table-column>
					<el-table-column prop="jbxxSshy" label="行业领域" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="jbxxSshyqt" label="其他行业" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="jbxxJzqd" label="集资渠道" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="processCityName" label="报送地市" width="120" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="uploadTime" label="报送时间" width="90" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间" width="90" :show-overflow-tooltip="true" sortable="custom"></el-table-column>
					<el-table-column prop="uploadStatus" label="案件状态" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span v-if="scope.row.uploadStatus == 0">新增待上报</span>
							<span v-if="scope.row.uploadStatus == 1">已上报</span>
							<span v-if="scope.row.uploadStatus == 2">被驳回</span>
							<span v-if="scope.row.uploadStatus == 10">新增待上报</span>
							<span v-if="scope.row.uploadStatus == 11">下级上报</span>
							<span v-if="scope.row.uploadStatus == 12">已上报</span>
							<span v-if="scope.row.uploadStatus == 13">已驳回</span>
						</template>
					</el-table-column>
					<el-table-column prop="rejectReason" label="驳回原因" width="150" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column fixed="right" prop="" label="操作" width="80">
						<template slot-scope="scope">
							<i class="iconfont icon-bianji color-m" title="编辑" @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;margin: 0 3px;"></i>
							<i class="iconfont icon-shangbao color-m" title="上报" v-show="scope.row.uploadStatus != 13" @click="handleReport(scope.$index, scope.row)" style="cursor: pointer;margin: 0 3px;"></i>
							<i class="iconfont icon-fanhui3 color-m" title="驳回" v-show="scope.row.uploadStatus == 11" @click="handleBack(scope.$index, scope.row)" style="cursor: pointer;margin: 0 3px;"></i>
						</template>
					</el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportONE" style="float: left;margin-top:10px;margin-right: 20px;">{{exportOneName}}</el-button>
				<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportALLFn" style="float: left;margin-top:10px;">{{exportAllName}}</el-button>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
				<el-button v-show="totalItems != 0" type="danger" size="mini" @click="handeleDelete" style="position: absolute;top: 25px;right: 90px;z-index: 999;">删除</el-button>
				<el-button v-show="formSearch.uploadStatus == 0" type="primary" size="mini" @click="handeleAdd" style="position: absolute;top: 25px;right: 20px;z-index: 999;">新增</el-button>
			</el-card>
		</div>
		
		<!-- 编辑   弹框 -->
		<el-dialog title="刑事案件信息" :visible.sync="dialogVisibleBJ" width="80%" class="madal-box-anjian" :close-on-click-modal="false" @close="closeHandle">
			<el-button type="primary" size="mini" @click="zeroStatusHandle" v-show="ruleFormBJ.zeroStatusShow" style="float: right;margin-right: 20px;">{{ruleFormBJ.zeroStatusName}}</el-button>
			<div :style="ruleFormBJ.zeroStatus == 0 ? 'width: 100%;height: 600px;' : 'width: 100%;'">
				<el-scrollbar style="height: 100%;width:100%">
					<el-form :model="ruleFormBJ" :rules="rulesFormBJ" ref="ruleFormBJ" label-width="160px" class="demo-ruleForm">
						<h4 class="title-h4">基本信息</h4>
						<el-row :gutter="30">
							<el-col :span="12">
								<el-form-item>
									<span slot="label">案件名称<font style="color: #F56C6C;">*</font></span>
									<el-row :gutter="0" v-show="ruleFormBJ.zeroStatus == 0">
										<el-col :span="2" style="text-align: left;line-height: 44px;margin-bottom: 10px;color: #ccc;">企业：</el-col>
										<el-col :span="22" style="text-align: left;">	
											<el-input v-model="ruleFormBJ.jbxxQymc"></el-input>
										</el-col>
									</el-row>
									<el-row :gutter="0" v-show="ruleFormBJ.zeroStatus == 0">
										<el-col :span="2" style="text-align: left;line-height: 43px;margin-bottom: 10px;color: #ccc;">个人：</el-col>
										<el-col :span="22" style="text-align: left;">	
											<el-input v-model="ruleFormBJ.jbxxGr"></el-input>
										</el-col>
									</el-row>
									<el-row :gutter="0">
										<el-col :span="2" style="text-align: left;line-height: 43px;color: #ccc;">其他：</el-col>
										<el-col :span="22" style="text-align: left;">	
											<el-input v-model="ruleFormBJ.jbxxQt"></el-input>
										</el-col>
									</el-row>
								</el-form-item>
							</el-col>
							<el-col :span="12" v-show="ruleFormBJ.zeroStatus == 0">
								<el-form-item label="" prop="jbxxJzrs">
									<span slot="label">参与集资人数<font style="color: #F56C6C;">*</font></span>
									<el-input v-model="ruleFormBJ.jbxxJzrs"></el-input>
								</el-form-item>
								<el-form-item label="" prop="czqkLasj">
									<span slot="label">立案时间<font style="color: #F56C6C;">*</font></span>
									<el-date-picker type="date" v-model="ruleFormBJ.czqkLasj" value-format="yyyy-MM-dd" :clearable="false"></el-date-picker>
								</el-form-item>
								<el-form-item label="" prop="fzczdxzjgssq">
									<span slot="label">立案地<font style="color: #F56C6C;">*</font></span>
									<el-cascader v-model="ruleFormBJ.fzczdxzjgssq" :options="ruleFormBJ.quyuList" :props="ruleFormBJ.quyuProps" ref="quyuRef" change-on-select style="width: 100%;"></el-cascader>
								</el-form-item>
							</el-col>
						</el-row>
						<div v-show="ruleFormBJ.zeroStatus == 0">
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="集资渠道" prop="jbxxJzqd">
										<span slot="label">集资渠道<font style="color: #F56C6C;">*</font></span>
										<el-select v-model="ruleFormBJ.jbxxJzqd">
											<el-option v-for="(item,index) in ruleFormBJ.financingChannelsList" :key="index" :label="item" :value="item"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="" prop="jbxxWdfje">
										<span slot="label">涉案金额<font style="color: #F56C6C;">*</font></span>
										<el-input v-model="ruleFormBJ.jbxxWdfje"></el-input>
									</el-form-item>
									<el-form-item prop="jbxxSshy">
										<span slot="label">行业领域<font style="color: #F56C6C;">*</font></span>
										<el-select v-model="ruleFormBJ.jbxxSshy" @change="jbxxSshyChange">
											<el-option v-for="(item,index) in ruleFormBJ.industryList" :key="index" :label="item" :value="item"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item prop="eventStatus">
										<span slot="label">案件类型<font style="color: #F56C6C;">*</font></span>
										<el-select v-model="ruleFormBJ.eventStatus">
											<el-option label="新发案件" :value="0"></el-option>
											<el-option label="存量案件" :value="1"></el-option>
											<!-- <el-option label="零上报" :value="2"></el-option> -->
										</el-select>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="立案罪名" prop="czqkLazm">
										<span slot="label">立案罪名<font style="color: #F56C6C;">*</font></span>
										<el-input v-model="ruleFormBJ.czqkLazm"></el-input>
									</el-form-item>
									<el-form-item label="是否跨省案件" prop="jbxxSfks">
										<span slot="label">是否跨省案件<font style="color: #F56C6C;">*</font></span>
										<div style="text-align: left;">
											<el-radio v-model="ruleFormBJ.jbxxSfks" :label="0">否</el-radio>
											<el-radio v-model="ruleFormBJ.jbxxSfks" :label="1">是</el-radio>
										</div>
									</el-form-item>
									<el-form-item prop="jbxxSshyqt" v-show="ruleFormBJ.jbxxSshy == '其他（需按《国民经济行业分类》（GB/T4754-2017）行业分类进行备注）'">
										<span slot="label">其他行业<font style="color: #F56C6C;">*</font></span>
										<el-input v-model="ruleFormBJ.jbxxSshyqt"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
						</div>
						<div v-show="ruleFormBJ.zeroStatus == 0">
							<h4 class="title-h4">结案情况</h4>		
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="判决时间" prop="czqkPjsj">
										<el-date-picker v-model="ruleFormBJ.czqkPjsj" type="date" value-format="yyyy-MM-dd"></el-date-picker>
									</el-form-item>
									<el-form-item label="判决罪名" prop="czqkPjzm">
										<el-select v-model="ruleFormBJ.czqkPjzm">
											<el-option label="非法吸收公众存款" value="非法吸收公众存款"></el-option>
											<el-option label="集资诈骗罪" value="集资诈骗罪"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="判决文书号" prop="czqkAh">
										<el-input v-model="ruleFormBJ.czqkAh"></el-input>
									</el-form-item>
									<el-form-item label="判决主要单位">
										<el-row :gutter="0">
											<el-col :span="2" style="text-align: left;line-height: 44px;margin-bottom: 10px;color: #ccc;">企业：</el-col>
											<el-col :span="22" style="text-align: left;">	
												<el-input v-model="ruleFormBJ.jaqkZspjqy"></el-input>
											</el-col>
										</el-row>
										<el-row :gutter="0">
											<el-col :span="2" style="text-align: left;line-height: 30px;margin-bottom: 10px;color: #ccc;">个人：</el-col>
											<el-col :span="22" style="text-align: left;">	
												<el-input v-model="ruleFormBJ.jaqkZspjgr"></el-input>
											</el-col>
										</el-row>
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									<el-form-item label="已清退资金金额" prop="czqkYqtzjze">
										<el-input v-model="ruleFormBJ.czqkYqtzjze" style="width: 90%;float: left;"></el-input> 万元
									</el-form-item>
									<el-form-item label="结案时间" prop="czqkBjsj">
										<el-date-picker v-model="ruleFormBJ.czqkBjsj" type="date" value-format="yyyy-MM-dd" :clearable="false"></el-date-picker>
									</el-form-item>
									<el-form-item label="其他办结情形时间" prop="czqkZzslsj">
										<el-date-picker v-model="ruleFormBJ.czqkZzslsj" type="date" value-format="yyyy-MM-dd"></el-date-picker>
									</el-form-item>
									<el-form-item label="其他办结情形" prop="czqkQtbjqx">
										<el-select v-model="ruleFormBJ.czqkQtbjqx">
											<el-option v-for="(item,index) in ruleFormBJ.czqkQtbjqxList" :key="index" :label="item" :value="item"></el-option>
										</el-select>
									</el-form-item>
									<el-form-item label="应当清退资金金额" prop="czqkYdqtzjze">
										<el-input v-model="ruleFormBJ.czqkYdqtzjze" style="width: 90%;float: left;"></el-input> 万元
									</el-form-item>
								</el-col>
							</el-row>
							<el-row :gutter="30">
								<el-col :span="24">
									<el-form-item label="备注" prop="czqkBz">
										<el-input type="textarea" :rows="2" v-model="ruleFormBJ.czqkBz"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
						</div>
					</el-form>
				</el-scrollbar>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitFormBJ('ruleFormBJ')">确 定</el-button>
			</span>
		</el-dialog>
		
		<!-- 驳回   弹框 -->
		<el-dialog title="驳回原因" :visible.sync="dialogVisibleBH" width="30%" class="madal-box-anjian" :close-on-click-modal="false">
			<div style="width: 100%;">
				<el-form :model="ruleFormBH" ref="ruleFormBH" label-width="0px" class="demo-ruleForm">
					<el-row :gutter="30">
						<el-col :span="24">
							<el-form-item label="" prop="rejectReason">
								<el-input type="textarea" :rows="2" v-model="ruleFormBH.rejectReason"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitFormBH('ruleFormBH')">确 定</el-button>
			</span>
		</el-dialog>
  </div>
</template>

<script>
import { exportExcel } from '../../provider/index.js' //导出Excel公用方法
export default {
  name: 'case-criminal',
  data() {
		return {
			baseUrl:this.$pulick.baseUrl,//默认路径目录  加载静态文件img
			levelSession:JSON.parse(sessionStorage.getItem("user_xjfj")).level,
			loadingFlag: false,
			pickerOptionsLA: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			pickerOptionsBS: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			formSearch:{
				companyName: this.$route.query.name!=undefined ? this.$route.query.name : '',
				timeLA:null,
				timeBS:null,
				uploadStatus: '0',
				eventStatus: '',
				prop: 'updateTime',
				order: '1',
				cityId: this.$route.query.cityId!=undefined ? Number(this.$route.query.cityId) : '',
				cityIdList:[],
			},
			dataTableEmpty:false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 15, //每页  条数
			totalItems: 0, //列表 总数
			multipleSelection: [], //导出参数   数据前  复选框
			exportOneName:"导出所选",
			exportAllName:"导出全部",
			dialogVisibleBJ: false, //是否打开查看弹框
			ruleFormBJ: {
				id: '',
				zeroStatus:0,//是否 零上报
				zeroStatusName:'零上报',
				zeroStatusShow:true,
				//省市区  层级选择器
				quyuList: [], //省市区   数据
				quyuProps: {
					lazy: true,
				},
				industryList: [],//行业   数据
				financingChannelsList: [],//集资渠道   数据
				czqkQtbjqxList:[],//其他办结情形
				jbxxQymc:"",//企业名称   企业
				jbxxGr:"",//企业名称   个人
				jbxxQt:"",//企业名称   其他
				eventStatus:0,//案件类型
				jbxxSshy:'',//行业
				jbxxSshyqt:'',//其他行业
				jbxxJzqd:'',//集资渠道
				jbxxWdfje:'',//涉案金额
				jbxxJzrs:'',//参与集资人数
				czqkLasj:'',//立案时间
				czqkLazm:'',//立案罪名
				fzczdxzjgssq: [],//立案地
				jbxxSfks:0,//是否跨省案件
				czqkPjsj:'',//判决时间
				czqkPjzm:'',//判决罪名
				czqkAh:"",//判决文书号
				jaqkZspjqy:"",//判决单位  企业
				jaqkZspjgr:"",//判决单位  个人
				czqkYqtzjze:'',//已清退资金金额
				czqkBjsj:'',//结案时间
				czqkZzslsj:'',//其他办结情形时间
				czqkQtbjqx:'',//其他办结情形
				czqkYdqtzjze:'',//应当清退资金金额
				czqkBz:'',//备注
			},
			rulesFormBJ: {
				eventStatus: [
					{ required: true, message: '请选择案件类型', trigger: 'change' }
				],
				jbxxSshy: [
					{ required: true, message: '请选择行业领域', trigger: 'change' }
				],
				jbxxSshyqt: [
					{ required: true, message: '请输入其他行业', trigger: 'blur' }
				],
				jbxxJzqd: [
					{ required: true, message: '请选择集资渠道', trigger: 'change' }
				],
				jbxxWdfje: [
					{ required: true, message: '请输入涉案金额', trigger: 'blur' },
				],
				jbxxJzrs: [
					{ required: true, message: '请输入参与集资人数', trigger: 'blur' },
				],
				fzczdxzjgssq: [
					{ required: true, message: '请选择立案地', trigger: 'change' },
				],
				czqkLazm: [
					{ required: true, message: '请输入立案罪名', trigger: 'blur' },
				],
				czqkLasj: [
					{ required: true, message: '请选择立案时间', trigger: 'blur' }
				], 
				/* czqkPjsj: [
					{ required: true, message: '请选择判决时间', trigger: 'blur' },
				],
				czqkPjzm: [
					{ required: true, message: '请输入判决罪名', trigger: 'change' },
				],
				czqkAh: [
					{ required: true, message: '请输入判决文书号', trigger: 'blur' },
				],
				czqkYqtzjze: [
					{ required: true, message: '请输入已清退资金金额', trigger: 'blur' },
				],
				czqkBjsj: [
					{ required: true, message: '请选择结案时间', trigger: 'blur' },
				],
				czqkYdqtzjze: [
					{ required: true, message: '请输入应当清退资金金额 ', trigger: 'blur' },
				], */
				/* czqkZzslsj: [
					{ required: true, message: '请选择其他办结情形时间', trigger: 'blur' },
				],
				czqkQtbjqx: [
					{ required: true, message: '请选择其他办结情形', trigger: 'change' },
				], */
			},
			dialogVisibleBH: false, //是否打开驳回弹框
			ruleFormBH:{
				id: '',
				rejectReason:''
			}
		};
  },
  mounted() {}, 
  created() {
		this.getCityIdList(); //加载列表
		this.getPageList(); //加载列表
		
		//加载   编辑弹框
		//省市区  层级选择器
		let _this = this; //this指向   重定义
		this.ruleFormBJ.quyuProps.lazyLoad = function lazyLoad(node, resolve) {
			//所对应节点数据
			const { level } = node;
			if(level < 2){
				//获取下一节点数据
				setTimeout(() => {
					_this.$provider.get('/wg-ifrRisk/upload/area', {
						params: {
							parentCode: level == 0 ? 0 : node.value
						}
					}).then(res => {
						const nodes = [];
						for (let i = 0; i < res.data.length; i++) {
							nodes.push({
								label: res.data[i].name,
								value: res.data[i].code,
								leaf: level >= 1
							});
						}
						// 通过调用resolve将子节点数据返回，通知组件数据加载完成
						resolve(nodes);
					})
				}, 500);
			} else {
				node.loading = false;
			}
		}
		this.getIndustryFinancingChannelsList();//加载弹框  行业  集资渠道
  },
  methods: {
		getCityIdList(){
			this.$provider.get('/wg-ifrRisk/upload/area', {
				params: {
					parentCode: 660000
				}
			}).then(res => {
				this.formSearch.cityIdList = res.data;
			})
		},
		//点击查询  按钮
		searchNameFn() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		handleClick(tab, event) {
			this.formSearch.uploadStatus = tab.index;
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//加载列表
		getPageList() {
			this.loadingFlag = true;//loading 显示
			let paramStr = this.getParams();
			this.dataTableEmpty = false;
			this.$provider.get('/wg-ifrRisk/upload/searchCriminalPage',{
				params:{
					name: paramStr.companyName.replace(/^\s+|\s+$/g,""),
					uploadStatus: paramStr.uploadStatus,
					eventStatus: paramStr.eventStatus,
					startTime: paramStr.startTime,
					endTime: paramStr.endTime,
					uploadStartTime: paramStr.uploadStartTime,
					uploadEndTime: paramStr.uploadEndTime,
					cityId:paramStr.cityId,
					orderColumn: paramStr.prop,
					orderDesc: paramStr.order,
					pageNo: this.currentPage,
					pageSize: this.pageSize,
				}
			}).then(res=>{
				this.loadingFlag = false;//loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//排序
		sortChange(column, prop, order) {
			if (column.order == "ascending") {
				this.formSearch.prop = column.prop;
				this.formSearch.order = "0";
			} else if (column.order == "descending") {
				this.formSearch.prop = column.prop;
				this.formSearch.order = "1";
			} else {
				this.formSearch.prop = "";
				this.formSearch.order = "";
			}
			this.getPageList(); //加载列表
		}, 
		
		//上报
		handleReport(index,item){
			if(confirm("确定上报本条数据吗？")){
				this.$provider.get('/wg-ifrRisk/upload/uploadCriminal', {
					params: {
						id: item.id,
					}
				}).then(res => {
					this.$message({
						message: res.msg,
						type: 'success'
					});
					this.getPageList();
				})
			}
		},
		//驳回
		handleBack(index,item){
			this.dialogVisibleBH = true;
			this.ruleFormBH.id = item.id;
			this.ruleFormBH.rejectReason = item.rejectReason;
		},
		//驳回  提交
		submitFormBH(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if(confirm("确定驳回本条数据吗？")){
						this.$provider.get('/wg-ifrRisk/upload/rejectCriminal', {
							params: {
								id: this.ruleFormBH.id,
								rejectReason: this.ruleFormBH.rejectReason,
							}
						}).then(res => {
							this.$message({
								message: res.msg,
								type: 'success'
							});
								this.dialogVisibleBH = false; //关闭弹框
							this.getPageList();
						})
					}
				} else {
					console.log('error submit!!');
				}
			});
		},
		
		//新增
		handeleAdd(){
			this.dialogVisibleBJ = true;
			//清除  表单验证
			this.$nextTick(() => {
				this.$refs['ruleFormBJ'].clearValidate();
			}) 
			this.ruleFormBJ.zeroStatus = 0;
			this.ruleFormBJ.zeroStatusShow = true;
			this.ruleFormBJ.id = "";
			this.ruleFormBJ.jbxxQymc = "";//案件名称  企业
			this.ruleFormBJ.jbxxGr = "";//案件名称  个人
			this.ruleFormBJ.jbxxQt = "";//案件名称  其他
			this.ruleFormBJ.eventStatus = "";//案件类型
			this.ruleFormBJ.jbxxSshy = "";//行业
			this.ruleFormBJ.jbxxSshyqt = "";//其他行业
			this.ruleFormBJ.jbxxJzqd = "";//集资渠道
			this.ruleFormBJ.jbxxWdfje = "";//涉案金额
			this.ruleFormBJ.jbxxJzrs = "";//参与集资人数
			this.ruleFormBJ.czqkLazm = "";//立案罪名
			this.ruleFormBJ.czqkLasj = "";//立案时间
			this.ruleFormBJ.jbxxSfks = "";//是否跨省案件
			this.ruleFormBJ.fzczdxzjgssq = [];//立案地
			this.ruleFormBJ.czqkPjsj = "";//判决时间
			this.ruleFormBJ.czqkPjzm = "";//判决罪名
			this.ruleFormBJ.czqkAh = "";//判决文书号
			this.ruleFormBJ.jaqkZspjqy = "";//判决主要单位  企业
			this.ruleFormBJ.jaqkZspjgr = "";//判决主要单位  个人
			this.ruleFormBJ.czqkYqtzjze = "";//已清退资金金额
			this.ruleFormBJ.czqkBjsj = "";//结案时间
			this.ruleFormBJ.czqkZzslsj = "";//其他办结情形时间
			this.ruleFormBJ.czqkQtbjqx = "";//其他办结情形
			this.ruleFormBJ.czqkYdqtzjze = "";//应当清退资金金额
			this.ruleFormBJ.czqkBz = "";//备注
		},
		//编辑 
		handleEdit(index,item){
			this.dialogVisibleBJ = true;
			//清除  表单验证
			this.$nextTick(() => {
				this.$refs['ruleFormBJ'].clearValidate();
			}) 
			this.ruleFormBJ.zeroStatusShow = false;
			this.ruleFormBJ.zeroStatus = item.zeroStatus;
			if(item.zeroStatus != 0){
				this.rulesFormBJ = {};
			} else {
				this.rulesFormBJ = {
					eventStatus: [
						{ required: true, message: '请选择案件类型', trigger: 'change' }
					],
					jbxxSshy: [
						{ required: true, message: '请选择行业领域', trigger: 'change' }
					],
					jbxxSshyqt: [
						{ required: true, message: '请输入其他行业', trigger: 'blur' }
					],
					jbxxJzqd: [
						{ required: true, message: '请选择集资渠道', trigger: 'change' }
					],
					jbxxWdfje: [
						{ required: true, message: '请输入涉案金额', trigger: 'blur' },
					],
					jbxxJzrs: [
						{ required: true, message: '请输入参与集资人数', trigger: 'blur' },
					],
					fzczdxzjgssq: [
						{ required: true, message: '请选择立案地', trigger: 'change' },
					],
					czqkLazm: [
						{ required: true, message: '请输入立案罪名', trigger: 'blur' },
					],
					czqkLasj: [
						{ required: true, message: '请选择立案时间', trigger: 'blur' }
					], 
				};
			}
			this.ruleFormBJ.id = item.id;
			this.ruleFormBJ.jbxxQymc = item.jbxxQymc;//案件名称  企业
			this.ruleFormBJ.jbxxGr = item.jbxxGr;//案件名称  个人
			this.ruleFormBJ.jbxxQt = item.jbxxQt;//案件名称  其他
			this.ruleFormBJ.eventStatus = item.eventStatus;//案件类型
			this.ruleFormBJ.jbxxSshy = item.jbxxSshy;//行业
			this.ruleFormBJ.jbxxSshyqt = item.jbxxSshyqt;//其他行业
			this.ruleFormBJ.jbxxJzqd = item.jbxxJzqd;//集资渠道
			this.ruleFormBJ.jbxxWdfje = item.jbxxWdfje;//涉案金额
			this.ruleFormBJ.jbxxJzrs = item.jbxxJzrs;//参与集资人数
			this.ruleFormBJ.czqkLazm = item.czqkLazm;//立案罪名
			this.ruleFormBJ.czqkLasj = item.czqkLasj;//立案时间
			this.ruleFormBJ.jbxxSfks = item.jbxxSfks;//是否跨省案件
			//立案地
			let provinceId = item.provinceId!=null ? item.provinceId : "";
			let cityId = item.cityId!=null ? item.cityId : "";
			this.ruleFormBJ.fzczdxzjgssq = [];
			if(provinceId != null && cityId == null){
				this.ruleFormBJ.fzczdxzjgssq.push(provinceId);
			} else if(provinceId != null && cityId != null){
				this.ruleFormBJ.fzczdxzjgssq.push(provinceId);
				this.ruleFormBJ.fzczdxzjgssq.push(cityId);
			} 
			this.ruleFormBJ.czqkPjsj = item.czqkPjsj;//判决时间
			this.ruleFormBJ.czqkPjzm = item.czqkPjzm;//判决罪名
			this.ruleFormBJ.czqkAh = item.czqkAh;//判决文书号
			this.ruleFormBJ.jaqkZspjqy = item.jaqkZspjqy;//判决主要单位  企业
			this.ruleFormBJ.jaqkZspjgr = item.jaqkZspjgr;//判决主要单位  个人
			this.ruleFormBJ.czqkYqtzjze = item.czqkYqtzjze;//已清退资金金额
			this.ruleFormBJ.czqkBjsj = item.czqkBjsj;//结案时间
			this.ruleFormBJ.czqkZzslsj = item.czqkZzslsj;//其他办结情形时间
			this.ruleFormBJ.czqkQtbjqx = item.czqkQtbjqx;//其他办结情形
			this.ruleFormBJ.czqkYdqtzjze = item.czqkYdqtzjze;//应当清退资金金额
			this.ruleFormBJ.czqkBz = item.czqkBz;//备注
		},
		//关闭弹框  回调
		closeHandle(){
			//清除  表单验证
			this.$nextTick(() => {
				this.$refs['ruleFormBJ'].clearValidate();
			}) 
		}, 
		//确定其他行业   校验与显隐
		jbxxSshyChange(){
			if(this.ruleFormBJ.jbxxSshy == '其他（需按《国民经济行业分类》（GB/T4754-2017）行业分类进行备注）'){
				this.ruleFormBJ.jbxxSshyqt='';
				this.rulesFormBJ.jbxxSshyqt[0].required=true;
			} else {
				this.ruleFormBJ.jbxxSshyqt='';
				this.rulesFormBJ.jbxxSshyqt[0].required=false;
			}
		},
		//零上报
		zeroStatusHandle(){
			if(this.ruleFormBJ.zeroStatus == 0){
				this.ruleFormBJ.zeroStatusName = "正常上报";
				this.ruleFormBJ.zeroStatus = 1;
				this.ruleFormBJ.jbxxQt = this.getDate() + "刑事案件零上报";
				this.rulesFormBJ = {};
			} else if(this.ruleFormBJ.zeroStatus == 1){
				this.ruleFormBJ.zeroStatusName = "零上报";
				this.ruleFormBJ.zeroStatus = 0;
				this.ruleFormBJ.tagsListQT = [];
				this.ruleFormBJ.jbxxQt = "";
				this.rulesFormBJ = {
					eventStatus: [
						{ required: true, message: '请选择案件类型', trigger: 'change' }
					],
					jbxxSshy: [
						{ required: true, message: '请选择行业领域', trigger: 'change' }
					],
					jbxxSshyqt: [
						{ required: true, message: '请输入其他行业', trigger: 'blur' }
					],
					jbxxJzqd: [
						{ required: true, message: '请选择集资渠道', trigger: 'change' }
					],
					jbxxWdfje: [
						{ required: true, message: '请输入涉案金额', trigger: 'blur' },
					],
					jbxxJzrs: [
						{ required: true, message: '请输入参与集资人数', trigger: 'blur' },
					],
					fzczdxzjgssq: [
						{ required: true, message: '请选择立案地', trigger: 'change' },
					],
					czqkLazm: [
						{ required: true, message: '请输入立案罪名', trigger: 'blur' },
					],
					czqkLasj: [
						{ required: true, message: '请选择立案时间', trigger: 'blur' }
					], 
				};
				//清除  表单验证
				this.$nextTick(() => {
					this.$refs['ruleFormBJ'].clearValidate();
				})
			}
		},
		getDate(){
			var date = new Date();
			var year=date.getFullYear();
			var month = date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
			return year + "年" + month + "月";
		},
		//编辑  提交
		submitFormBJ(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if(this.ruleFormBJ.zeroStatus == 0){
						if(this.$refs['quyuRef'].getCheckedNodes()[0] == null){
							this.$message({
								message: '立案地不能为空，请选择',
								type: 'warning'
							});
						} else {
							let quyuName = this.$refs['quyuRef'].getCheckedNodes()[0].pathLabels;
							this.$provider.post('/wg-ifrRisk/upload/saveCriminal', {
								id : this.ruleFormBJ.id,
								zeroStatus : this.ruleFormBJ.zeroStatus,
								jbxxQymc : this.ruleFormBJ.jbxxQymc,//案件名称  企业
								jbxxGr : this.ruleFormBJ.jbxxGr,//案件名称  个人
								jbxxQt : this.ruleFormBJ.jbxxQt,//案件名称  其他
								jbxxSshy : this.ruleFormBJ.jbxxSshy,//行业
								jbxxSshyqt : this.ruleFormBJ.jbxxSshy=='其他（需按《国民经济行业分类》（GB/T4754-2017）行业分类进行备注）' ? this.ruleFormBJ.jbxxSshyqt : '',//其他行业
								jbxxJzqd : this.ruleFormBJ.jbxxJzqd,//集资渠道
								jbxxWdfje : this.ruleFormBJ.jbxxWdfje,//涉案金额
								jbxxJzrs : this.ruleFormBJ.jbxxJzrs,//参与集资人数 
								czqkLasj : this.ruleFormBJ.czqkLasj,//立案时间 
								czqkLazm : this.ruleFormBJ.czqkLazm,//立案罪名
								provinceName : quyuName.length >= 1 ? quyuName[0] : "",
								cityName : quyuName.length >= 2 ? quyuName[1] : "",
								provinceId : this.ruleFormBJ.fzczdxzjgssq.length >= 1 ? this.ruleFormBJ.fzczdxzjgssq[0] : "",
								cityId :	this.ruleFormBJ.fzczdxzjgssq.length >= 2 ? this.ruleFormBJ.fzczdxzjgssq[1] : "",
								eventStatus : this.ruleFormBJ.eventStatus,
								jbxxSfks : this.ruleFormBJ.jbxxSfks,//是否跨省案件
								czqkPjsj : this.ruleFormBJ.czqkPjsj,//判决时间
								czqkPjzm : this.ruleFormBJ.czqkPjzm,//判决罪名
								czqkAh : this.ruleFormBJ.czqkAh,//判决文书号
								jaqkZspjqy : this.ruleFormBJ.jaqkZspjqy,//判决主要单位  企业
								jaqkZspjgr : this.ruleFormBJ.jaqkZspjgr,//判决主要单位  个人
								czqkYqtzjze : this.ruleFormBJ.czqkYqtzjze,//已清退资金金额
								czqkBjsj : this.ruleFormBJ.czqkBjsj,//结案时间
								czqkZzslsj : this.ruleFormBJ.czqkZzslsj,//其他办结情形时间
								czqkQtbjqx : this.ruleFormBJ.czqkQtbjqx,//其他办结情形
								czqkYdqtzjze : this.ruleFormBJ.czqkYdqtzjze,//应当清退资金金额
								czqkBz : this.ruleFormBJ.czqkQtbjqx,//备注
							}).then(res => {
								if(this.ruleFormBJ.id == ""){
									this.$message({
										message: '新增成功',
										type: 'success'
									});
								} else {
									this.$message({
										message: '编辑成功',
										type: 'success'
									});
								}
								this.dialogVisibleBJ = false; //关闭弹框
								this.getPageList(); //加载列表
							}) 
						}
					} else {
						this.$provider.post('/wg-ifrRisk/upload/saveCriminal', {
							id : this.ruleFormBJ.id,
							zeroStatus : this.ruleFormBJ.zeroStatus,
							jbxxQymc : '',//案件名称  企业
							jbxxGr : '',//案件名称  个人
							jbxxQt : this.ruleFormBJ.jbxxQt,//案件名称  其他
							jbxxSshy : '',//行业
							jbxxSshyqt : '',//其他行业
							jbxxJzqd : '',//集资渠道
							jbxxWdfje : '',//涉案金额
							jbxxJzrs : '',//参与集资人数 
							czqkLasj : '',//立案时间 
							czqkLazm : '',//立案罪名
							provinceName :  "",
							cityName : "",
							provinceId : "",
							cityId : "",
							eventStatus : "",//案件类型
							jbxxSfks : "",//是否跨省案件
							czqkPjsj : "",//判决时间
							czqkPjzm : "",//判决罪名
							czqkAh : "",//判决文书号
							jaqkZspjqy : "",//判决主要单位  企业
							jaqkZspjgr : "",//判决主要单位  个人
							czqkYqtzjze : "",//已清退资金金额
							czqkBjsj : "",//结案时间
							czqkZzslsj : "",//其他办结情形时间
							czqkQtbjqx : "",//其他办结情形
							czqkYdqtzjze : "",//应当清退资金金额
							czqkBz : "",//备注
						}).then(res => {
							if(this.ruleFormBJ.id == ""){
								this.$message({
									message: '新增成功',
									type: 'success'
								});
							} else {
								this.$message({
									message: '编辑成功',
									type: 'success'
								});
							}
							this.dialogVisibleBJ = false; //关闭弹框
							this.getPageList(); //加载列表
						})
					}	
				} else {
					console.log('error submit!!');
				}
			});
		},
		//加载弹框  行业  集资渠道
		getIndustryFinancingChannelsList() {
			this.$provider.get('/wg-ifrRisk/upload/dict',{}).then(res=>{
				this.ruleFormBJ.industryList = res.data.industry;
				this.ruleFormBJ.financingChannelsList = res.data.financingChannels;
				this.ruleFormBJ.czqkQtbjqxList = res.data.czqkQtbjqx;
			})
		},
		
		//表格 每行  复选框选值    导出  删除
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		//删除所选
		handeleDelete() {
			let chk_value = '';
			this.multipleSelection.forEach(function(f, i) {
				if (i == 0) {
					chk_value = f.id;
				} else {
					chk_value += "," + f.id;
				}
			})
			if (chk_value.length != 0) {
				this.$provider.get('/wg-ifrRisk/upload/deleteCriminal', {
					params: {
						id: chk_value,
					}
				}).then(res => {
					this.$message({
						message: '删除成功',
						type: 'success'
					});
					this.getPageList();
				})
			} else {
				this.$message({
					message: '请先勾选',
					type: 'warning'
				});
			}
		},
		//导出所选
		exportONE() {
			let chk_value = '';
			this.multipleSelection.forEach(function(f, i) {
				if (i == 0) {
					chk_value = f.id;
				} else {
					chk_value += "," + f.id;
				}
			})
			if (chk_value.length != 0) {
				let myObj = {
					method: 'get',
					url: this.$pulick.urlStr+'/wg-ifrRisk/upload/exportCriminalByIds',
					fileName: '刑事案件',
					params: 'ids='+chk_value
				}
				this.exportOneName = "正在导出中...";
				exportExcel(myObj,this);// 导出Excel公用方法
			} else {
				this.$message({
					message: '请先勾选',
					type: 'warning'
				});
			}
		},
		//导出全部
		exportALLFn() {
			let paramStr = this.getParams();
			let myObj = {
				method: 'get',
				url: this.$pulick.urlStr+'/wg-ifrRisk/upload/exportCriminalAll',
				fileName: '刑事案件',
				params: 'name='+paramStr.companyName+'&uploadStatus='+paramStr.uploadStatus+'&eventStatus='+paramStr.eventStatus+'&cityId='+paramStr.cityId+'&startTime='+paramStr.startTime+'&endTime='+paramStr.endTime+'&uploadStartTime='+paramStr.uploadStartTime+'&uploadEndTime='+paramStr.uploadEndTime
			} 
			this.exportAllName = "正在导出中...";
			exportExcel(myObj,this);// 导出Excel公用方法
		},
		
		//获取参数
		getParams(){
			let obj = {
				companyName: this.formSearch.companyName,
				uploadStatus:this.formSearch.uploadStatus,
				eventStatus:this.formSearch.eventStatus,
				startTime: this.formSearch.timeLA != null ? this.formSearch.timeLA[0] : "",
				endTime: this.formSearch.timeLA != null ? this.formSearch.timeLA[1] : "",
				uploadStartTime: this.formSearch.timeBS != null ? this.formSearch.timeBS[0] : "",
				uploadEndTime: this.formSearch.timeBS != null ? this.formSearch.timeBS[1] : "",
				cityId: this.formSearch.cityId,
				prop: this.formSearch.prop,
				order: this.formSearch.order,
			}
			return obj;
		}
  }
};
</script>

<style lang="stylus" scoped="scoped"></style>
